<template>
  <form>
    <v-row class="py-0">
      <v-col class="py-0" cols="12" sm="12" md="11" lg="11">
        <detail :id="id" :check="check"></detail>
      </v-col>
      <v-col cols="12" sm="12" md="1">
        <v-row class="py-0 mb-2">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="primary" v-on="on" @click="back">
                  <v-icon>
                    mdi-arrow-left
                  </v-icon>
                </v-btn>
              </template>
              <span>Back</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <v-row v-if="!check.reverse">
          <v-col cols="12" class="py-0">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn color="success" v-on="on" @click="openDialog">
                  <v-icon>
                    mdi-reload
                  </v-icon>
                </v-btn>
              </template>
              <span>Reverse</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <dialog-reverse
      :dialog="dialog"
      :journals="journals"
      @save="reverse"
      @close="close"
    ></dialog-reverse>
  </form>
</template>

<script>
import Detail from "./DetailCheck";
import DialogReverse from "../giro/DialogReverse.vue";

export default {
  name: "check-view",
  components: {
    Detail,
    DialogReverse,
  },
  props: {
    id: Number,
  },
  data: () => ({
    check: {},
    journals: [],
    dialog: false,
  }),
  methods: {
    async getCheck() {
      this.$store.commit("SET_LOADER", true);
      const response = await this.$store.dispatch("check/getById", this.id);
      if (response.status === 200) {
        this.check = response.data;
        this.$store.commit("SET_LOADER", false);
      }
    },
    back() {
      this.$router.go(-1);
    },
    async openDialog() {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("check/getPreviewJournals", this.id)
        .then(response => {
          this.journals = response.data;
          this.dialog = true;
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    async reverse(params) {
      this.$store.commit("SET_LOADER", true);
      await this.$store
        .dispatch("check/saveJournals", { items: params.items, id: this.id, date: params.date })
        .then(() => {
          this.$store.commit("SET_LOADER", false);
        })
        .catch(() => {
          this.$store.dispatch("toast", {
            type: "error",
            message: "Ada masalah dengan aplikasi, mohon hubungi admin aplikasi",
          });
          this.$store.commit("SET_LOADER", false);
        });
    },
    close() {
      this.dialog = false;
    },
  },
  mounted() {
    this.getCheck();
  },
};
</script>

<style></style>
