<template>
  <v-row>
    <v-col cols="12" sm="12" md="6" lg="6" xl="6">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Bank</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <span>{{ check.bankName }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Check Number</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <span>{{ check.checkNumber }}</span>
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" sm="12" md="12" lg="12" xl="12">
          <v-row>
            <v-col cols="4" sm="4" md="3" lg="3" class="py-0">
              <v-subheader>Used</v-subheader>
            </v-col>
            <v-col cols="8" sm="8" md="4" lg="4" class="py-0">
              <span>{{ check.used }}</span>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "detail-check",
  props: {
    id: Number,
    check: Object,
  },
};
</script>

<style></style>
